aside {
  min-width: 30vmin;
  display: flex;
  flex-direction: column;
  gap: 1vmin;
  padding: 2vmin 20px;
  overflow-y: auto;
  box-shadow: 2px 0 1px -2px #000;
  background-color: #fff;

  button {
    &.active {
      border: 1px solid blue;
      color: blue;
      font-weight: 600;
    }
  }
}
