.login-form {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  max-width: 90vw;
  margin: auto;
  padding: 2vmin 4vmin;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 2px #000;
  background-color: #fff;

  h4 {
    font-size: 4vmin;
  }

  .error-message-box {
    font-size: 2vmin;
    border: 1px solid #ff0000bf;
    color: #ff0000bf;
    padding: 1.5vmin;
    border-radius: 6px;
    line-height: 100%;
  }

  button {
    margin-top: 24px;
    border-radius: 8px;
    text-transform: uppercase;
  }
}
