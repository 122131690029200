.header {
  padding: 2vmin 5vmin;
  font-size: 3vmin;
  font-weight: 500;
  text-transform: uppercase;
  box-shadow: 0 2px 3px -3px #000;
  background-color: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
