.company-select-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .content-wrapper {
    flex-direction: column;
    gap: 50px;
    display: flex;
  }

  button {
    margin-left: auto;
  }
}
